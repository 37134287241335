import { isValidElement, ReactNode } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Frontmatter } from '~/config';

export const extractSlug = (path: string) =>
  path?.split('/').pop()?.split('.').shift() || path;

export const frontmatterWithSlug = <
  F extends Record<string, unknown> = Frontmatter,
>(
  nodes: { frontmatter: F; node: { absolutePath: string } }[],
): (F & { slug?: string })[] =>
  nodes.map((node) => {
    const slug = extractSlug(node.node.absolutePath);
    let data: F = {
      ...node.frontmatter,
      slug,
      to: `/${slug}`,
    };

    // for page data, also add `to` for links and anchors to simplify component code
    if (Array.isArray(data.anchors)) {
      data = {
        ...data,
        anchors: data.anchors.map((anchor) =>
          anchor.externalLink
            ? anchor
            : {
                ...anchor,
                to: `/${data.slug}#${anchor.id}`,
              },
        ),
      };
    }
    return data;
  });

export const randomHex = () => {
  const hex = ((Math.random() * 0xffffff) << 0).toString(16);
  return hex.padEnd(6, hex.slice(-1));
};

/**
 * For cases where all you have to generate a JSX key is a piece of
 * body text or something else that might contain non-alpha characters
 *
 * @param obj A string, ReactNode, or unique chunk of data
 */
export const makeKey = (obj: string | ReactNode | unknown) => {
  let key: string | undefined = undefined;

  if (
    typeof obj === 'object' ||
    typeof obj === 'function' ||
    typeof obj === 'string' ||
    typeof obj === 'boolean' ||
    typeof obj === 'bigint' ||
    typeof obj === 'number' ||
    typeof obj === 'symbol'
  ) {
    if (isValidElement(obj)) {
      key = renderToStaticMarkup(obj);
    } else {
      key = JSON.stringify(obj);
    }
  }

  return (
    key?.replace(/[^a-zA-Z]/g, '').slice(0, 100) ??
    // fallback: this is not desired, it will cause the element to always
    // rerender -- try to be sure you're passing in something usable!
    randomHex()
  );
};

export const isExternalUrl = (to: string) => /^https?:\/\//.test(to);

export const prependSlash = (to: string) =>
  !to || to.charAt(0) === '/' ? to : `/${to}`;

/** converts an object into a querystring */
export const encodeQueryString = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};
