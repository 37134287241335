import { createContext, FC, useCallback, useState } from 'react';
import { ChildrenProps, isSSG } from '~/config';

export type OverlayContextValue = {
  isScrollLocked: boolean;
  setScrollLocked: (lock: boolean) => void;
};

export const OverlayContext = createContext<OverlayContextValue>({
  isScrollLocked: false,
  setScrollLocked: () => {},
});

// provider

type OverlayProps = ChildrenProps & Partial<OverlayContextValue>;

/**
 * Wire in at top level: AppContextProviders
 */
export const OverlayContextProvider: FC<OverlayProps> = ({
  isScrollLocked = false,
  children,
}) => {
  const [scrollLocked, setScrollStateLocked] = useState(isScrollLocked);

  const setScrollLocked = useCallback((lock: boolean) => {
    setScrollStateLocked(lock);
    if (!isSSG) {
      document.body.style.overflow = lock ? 'hidden' : 'auto';
    }
  }, []);

  return (
    <OverlayContext.Provider
      value={{ isScrollLocked: scrollLocked, setScrollLocked }}
    >
      {children}
    </OverlayContext.Provider>
  );
};
