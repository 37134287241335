exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-access-and-support-tsx": () => import("./../../../src/pages/access-and-support.tsx" /* webpackChunkName: "component---src-pages-access-and-support-tsx" */),
  "component---src-pages-early-stage-breast-cancer-tsx": () => import("./../../../src/pages/early-stage-breast-cancer.tsx" /* webpackChunkName: "component---src-pages-early-stage-breast-cancer-tsx" */),
  "component---src-pages-guidelines-tsx": () => import("./../../../src/pages/guidelines.tsx" /* webpackChunkName: "component---src-pages-guidelines-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-isi-tsx": () => import("./../../../src/pages/isi.tsx" /* webpackChunkName: "component---src-pages-isi-tsx" */),
  "component---src-pages-library-index-tsx": () => import("./../../../src/pages/library/index.tsx" /* webpackChunkName: "component---src-pages-library-index-tsx" */),
  "component---src-pages-library-lib-burger-tsx": () => import("./../../../src/pages/library/lib-burger.tsx" /* webpackChunkName: "component---src-pages-library-lib-burger-tsx" */),
  "component---src-pages-library-lib-content-section-tsx": () => import("./../../../src/pages/library/lib-content-section.tsx" /* webpackChunkName: "component---src-pages-library-lib-content-section-tsx" */),
  "component---src-pages-library-lib-cta-tsx": () => import("./../../../src/pages/library/lib-cta.tsx" /* webpackChunkName: "component---src-pages-library-lib-cta-tsx" */),
  "component---src-pages-library-lib-deepdive-tsx": () => import("./../../../src/pages/library/lib-deepdive.tsx" /* webpackChunkName: "component---src-pages-library-lib-deepdive-tsx" */),
  "component---src-pages-library-lib-footnotes-tsx": () => import("./../../../src/pages/library/lib-footnotes.tsx" /* webpackChunkName: "component---src-pages-library-lib-footnotes-tsx" */),
  "component---src-pages-library-lib-hero-tsx": () => import("./../../../src/pages/library/lib-hero.tsx" /* webpackChunkName: "component---src-pages-library-lib-hero-tsx" */),
  "component---src-pages-library-lib-internal-page-tsx": () => import("./../../../src/pages/library/lib-internal-page.tsx" /* webpackChunkName: "component---src-pages-library-lib-internal-page-tsx" */),
  "component---src-pages-library-lib-isi-tsx": () => import("./../../../src/pages/library/lib-isi.tsx" /* webpackChunkName: "component---src-pages-library-lib-isi-tsx" */),
  "component---src-pages-library-lib-lotties-tsx": () => import("./../../../src/pages/library/lib-lotties.tsx" /* webpackChunkName: "component---src-pages-library-lib-lotties-tsx" */),
  "component---src-pages-library-lib-references-tsx": () => import("./../../../src/pages/library/lib-references.tsx" /* webpackChunkName: "component---src-pages-library-lib-references-tsx" */),
  "component---src-pages-library-lib-twin-playground-tsx": () => import("./../../../src/pages/library/lib-twin-playground.tsx" /* webpackChunkName: "component---src-pages-library-lib-twin-playground-tsx" */),
  "component---src-pages-mechanism-tsx": () => import("./../../../src/pages/mechanism.tsx" /* webpackChunkName: "component---src-pages-mechanism-tsx" */),
  "component---src-pages-metastatic-breast-cancer-tsx": () => import("./../../../src/pages/metastatic-breast-cancer.tsx" /* webpackChunkName: "component---src-pages-metastatic-breast-cancer-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-request-information-tsx": () => import("./../../../src/pages/request-information.tsx" /* webpackChunkName: "component---src-pages-request-information-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-risk-of-recurrence-tsx": () => import("./../../../src/pages/risk-of-recurrence.tsx" /* webpackChunkName: "component---src-pages-risk-of-recurrence-tsx" */),
  "component---src-pages-site-map-tsx": () => import("./../../../src/pages/site-map.tsx" /* webpackChunkName: "component---src-pages-site-map-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

