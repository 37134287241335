import Cookies from 'js-cookie-remove-all';
import { Context, createContext, FC, useEffect, useState } from 'react';
import { ChildrenProps, isSSG } from '~/config';
import {
  getStoredBoolean,
  navigateSafe,
  removeItem,
  storeBoolean,
} from '~/utils';

/**
 * Import and use to create unique context instances, e.g.
 *
 * `export const MyContext = createContext(irritatorDefaults);`
 *
 * `<IrritatorContextProvider context={MyContext} ...>`
 */
export const irritatorDefaults = {
  /** False before user accept, true after mount if cookied. */
  hasAcceptedIrritator: false,
  /** Use to show your dialog. True after mount if not cookied. */
  isIrritatorOpen: false,
  /** Sets `isIrritatorOpen` to true. */
  openIrritator: () => {},
  /** Sets `isIrritatorOpen` to false. Does accept if `true` passed. */
  closeIrritator: (accept = false) => {},
  /** Toggles the `isIrritatorOpen` state. */
  toggleIrritator: () => {},
  /** Sets `hasAcceptedIrritator` to true, stores cookie. */
  acceptIrritator: () => {},
};

/**
 * Convenience if you only have one main irritator per app.
 * See the IrritatorContextProvider docs for multiple contexts.
 */
export const MainIrritatorContext = createContext(irritatorDefaults);

export type IrritatorContextProviderProps = ChildrenProps & {
  /**
   * Required to associate the context with a specific action. Non-reactive.
   *
   * Um, please don't put "irritator" in the key. It's a Y'all pet-name for
   * 'violator'-type dialogs but not for the eyes of the world! Scope keys
   * like `com.acme.acceptedTOS`, `acmeCorp:acceptedTOS`, etc. - thanks!
   */
  localStorageKey: string;

  /**
   * Defaults to MainIrritatorContext if not passed.
   * See the IrritatorContextProvider docs for multiple contexts.
   */
  context?: Context<typeof irritatorDefaults>;
};

/**
 * An "irritator" is a pop-up or banner that shows on first landing and
 * must be accepted to not show again. Acceptance is persisted in local
 * storage using the key you provide. To reset local storage for testing,
 * add #reset to any url.
 *
 * This provider can be used multiple times for different irritators. If
 * your app only needs one you can skip the `context` prop and use the
 * `MainIrritatorContext` default in components.
 *
 * For multiple contexts (or just a better-named one!) follow these steps:
 * 1. In a separate file from this one, export a new context,
 *    `export const TermsModalContext = createContext(irritatorDefaults);`
 * 2. Add an instance of this provider the top level of the app, passing a
 *    unique localStorageKey and your custom context
 * 3. Your components can now do `useContext(MyContext)`. 🎉 Rinse, repeat.
 *
 * Pro tip: you can remap fields to make component code more understandable:
 * `{ toggleIrritator: toggleTermsModal, acceptIrritator: acceptTerms }`
 */
export const IrritatorContextProvider: FC<IrritatorContextProviderProps> = ({
  context = MainIrritatorContext,
  localStorageKey,
  children,
}) => {
  if (!localStorageKey) {
    throw new Error(
      'Error: a unique local storage key must be passed to IrritatorContextProvider',
    );
  }

  const [hasAcceptedIrritator, setHasAcceptedIrritator] = useState(false);

  const [isIrritatorOpen, setIsIrritatorOpen] = useState(
    false /* set after mount in useEffect */,
  );

  useEffect(() => {
    /** Add #reset to url to clear viewed state for testing */
    if (!isSSG && window.location.hash === '#reset') {
      removeItem(localStorageKey);
      setHasAcceptedIrritator(false);
      setIsIrritatorOpen(true);
      Cookies.removeAll();

      const delay = setTimeout(
        () => navigateSafe(window.location.pathname, true),
        500,
      );
      return () => clearTimeout(delay);
    }

    /** Open after client-side mount */
    if (getStoredBoolean(localStorageKey)) {
      setHasAcceptedIrritator(true);
    } else {
      setIsIrritatorOpen(true);
    }
  }, [localStorageKey]);

  const toggleIrritator = () => {
    if (hasAcceptedIrritator) {
      setIsIrritatorOpen(!isIrritatorOpen);
    }
  };

  const openIrritator = () => {
    if (!isIrritatorOpen) {
      setIsIrritatorOpen(true);
    }
  };

  const closeIrritator = (accept = false) => {
    if (isIrritatorOpen) {
      setIsIrritatorOpen(false);
    }
    if (accept) {
      acceptIrritator();
    }
  };

  const acceptIrritator = () => {
    storeBoolean(localStorageKey);
    setHasAcceptedIrritator(true);
  };

  return (
    <context.Provider
      value={{
        hasAcceptedIrritator,
        isIrritatorOpen,
        openIrritator,
        closeIrritator,
        toggleIrritator,
        acceptIrritator,
      }}
    >
      {children}
    </context.Provider>
  );
};
