import { createContext, FC } from 'react';
import { ChildrenProps } from '~/config';

export type LocationContextValue = {
  currentLocation?: Location;
  currentHash?: string;
  debug: boolean;
};

export const LocationContext = createContext<LocationContextValue>({
  currentLocation: undefined,
  currentHash: undefined,
  debug: false,
});

// provider

export type LocationProps = ChildrenProps & {
  location?: Location;
};

/**
 * Wire in at top level: AppContextProviders
 */
export const LocationContextProvider: FC<LocationProps> = ({
  location,
  children,
}) => {
  return (
    <LocationContext.Provider
      value={{
        currentLocation: location,
        currentHash: location?.hash,
        debug: !!location?.search?.includes('debug'),
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
