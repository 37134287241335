export const isDev =
  (process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV) === 'development';

export const isSSG = typeof window === 'undefined';

export const isNetlify = isSSG
  ? process.env.NETLIFY === 'true'
  : window.location.hostname.includes('netlify.app');

export const isAzure = isSSG
  ? process.env.AZURE === 'true'
  : window.location.hostname.includes('nerlynxhcp.com') ||
    window.location.hostname.startsWith('witty-moss-0037f1e10'); // This is the instance name on Azure

// process.env.CONTEXT is set by Netlify when hosting there
export const isProduction =
  !isDev &&
  (isSSG
    ? (isNetlify && process.env.CONTEXT === 'production') ||
      (isAzure && process.env.REF === 'main')
    : isAzure && window.location.hostname === 'nerlynxhcp.com');

export const isClientStaging =
  isAzure &&
  (isSSG
    ? process.env.REF && process.env.REF !== 'main'
    : [
        'client.nerlynxhcp.com',
        'agency.nerlynxhcp.com',
        'dev.nerlynxhcp.com',
        'stage.nerlynxhcp.com',
      ].includes(window.location.hostname) ||
      window.location.hostname.startsWith('witty-moss-0037f1e10'));

export const isDevOrStaging = isDev || isNetlify;

export enum LocalStorageKey {
  viewedHCPModal = 'com.nerlynx.viewedHCPModal',
  viewedIndications = 'com.nerlynx.viewedIndications',
  viewedInitialIsi = 'com.nerlynx.viewedInitialIsi',
}
// e.g.: acceptedEULA = 'com.nerlynx.experience.accepted_eula',
