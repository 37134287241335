import { navigate } from 'gatsby';
import { isSSG } from '~/config';

/**
 * Use this instead of `navigate` to avoid build problems with SSG
 *
 * @param pathname e.g. '/pagename'
 * @param replace pass true for redirect
 */
export const navigateSafe = (pathname: string, replace = false) => {
  !isSSG && navigate(pathname, { replace });
};
