import { css } from 'twin.macro';
import { TableData } from '.';

// Don't import <NERLYNX /> or other helpers here, breaks the server (import order issue or something)

export const mbcSafetyData: TableData = {
  groupHeading1: (
    <span key="group-head-1">
      {/* see note above */}
      <span tw="uppercase text-base font-medium md:(text-lg)">Nerlynx</span> +
      capecitabine
      <br />
      (n=303)
    </span>
  ),

  groupHeading2: (
    <span key="group-head-1">
      Lapatinib + capecitabine
      <br />
      (n=311)
    </span>
  ),

  headings: [
    <>All&nbsp;grades, %</>,
    <>Grades&nbsp;&ge;3, %</>,
    <>All&nbsp;grades, %</>,
    <>Grades&nbsp;&ge;3, %</>,
  ],

  rows: [
    ['{D}', '83', '25*', '66', '13*'],
    ['Nausea', '53', '4.3', '42', '2.9'],
    ['Vomiting', '46', '4', '31', '1.9'],
    ['Fatigue/asthenia', '45', '6', '40', '4.5'],
    ['Decreased Appetite', '35', '2.6', '22', '2.3'],
    ['Constipation', '31', '1', '13', '0'],
    ['Weight Decreased', '20', '0.3', '13', '0.6'],
    ['Dizziness', '14', '0.3', '10', '0.6'],
    ['Back pain', '10', '0.3', '8', '0.3'],
    ['Arthralgia', '10', '0', '6', '1'],
    ['Urinary Tract Infection', '9', '0.7', '4.2', '0.6'],
    [
      <>
        Upper respiratory
        <br />
        tract infection
      </>,
      '8',
      '0.3',
      '4.5',
      '0.3',
    ],
    ['Abdominal Distention', '8', '0.3', '3.2', '0.6'],
    [
      <>
        Renal impairment
        <span
          css={css`
            font-size: 65%;
            vertical-align: 4px;
          `}
        >
          †
        </span>
      </>,
      '7',
      <>
        2 (Grade 3)
        <br />
        0.3 (Grade 4)
      </>,
      '1',
      <>
        0 (Grade 3)
        <br />
        0.3 (Grade 4)
      </>,
    ],
    ['Muscle Spasms', '5', '0', '1.9', '0'],
  ],
};
