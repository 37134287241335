import 'focus-visible/dist/focus-visible.min';

/**
 * Loads polyfills. Be sure this file is imported into gastby-browser.js or Layout.tsx.
 */
async function loadPolyfills() {
  // adds IntersectionObserver support for Safari < 12.2
  if (
    typeof window !== 'undefined' &&
    typeof window.IntersectionObserver === 'undefined'
  ) {
    await import('intersection-observer');
  }

  // adds smooth scrolling support for Safari, mobile Safari, IE, and Edge
  if (
    typeof window !== 'undefined' &&
    typeof document.documentElement.style.scrollBehavior === 'undefined'
  ) {
    /* 
    smoothscroll-anchor-polyfill requires smoothscroll-polyfill and this css,
    which can't be set here using javascript.
    
    html {
      scroll-behavior: smooth;
      --scroll-behavior: smooth;
    }
    */
    const smoothscroll = await import('smoothscroll-polyfill');
    await import('smoothscroll-anchor-polyfill');
    smoothscroll.polyfill();
  }
}

loadPolyfills();
