import { createContext, FC } from 'react';
import { LocalStorageKey } from '~/config';
// avoid circular imports via index from this file!
import {
  IrritatorContextProvider,
  irritatorDefaults,
} from './irritator-context';
import { LocationContextProvider, LocationProps } from './location-context';
import { OverlayContextProvider } from './overlay-context';

export const HCPContext = createContext(irritatorDefaults);

export const IndicationsContext = createContext(irritatorDefaults);

export const InitialIsiContext = createContext(irritatorDefaults);

export const AppContextProviders: FC<LocationProps> = ({
  location,
  children,
}) => {
  return (
    <LocationContextProvider location={location}>
      <OverlayContextProvider>
        <IrritatorContextProvider
          context={HCPContext}
          localStorageKey={LocalStorageKey.viewedHCPModal}
        >
          <IrritatorContextProvider
            context={IndicationsContext}
            localStorageKey={LocalStorageKey.viewedIndications}
          >
            <IrritatorContextProvider
              context={InitialIsiContext}
              localStorageKey={LocalStorageKey.viewedInitialIsi}
            >
              {children}
            </IrritatorContextProvider>
          </IrritatorContextProvider>
        </IrritatorContextProvider>
      </OverlayContextProvider>
    </LocationContextProvider>
  );
};
