import { css, Global } from '@emotion/react';
import tw, { GlobalStyles as BaseStyles } from 'twin.macro';
import './root.scss';

/**
 * Sets up global styles -- for :root variables please use root.scss
 */
const customStyles = css`
  html {
    /* Required css for smoothscroll-anchor-polyfill - see utils/polyfills.ts */
    scroll-behavior: smooth;
    --scroll-behavior: smooth;
  }

  body {
    ${tw`font-primary text-base text-brand-gray`};
  }

  h1 {
    ${tw`font-secondary text-brand-yellow text-3xl md:text-5xl my-2`}
  }

  h2 {
    ${tw`font-secondary text-brand-cyan text-2xl md:text-4xl my-3`}
  }

  h3 {
    ${tw`uppercase font-bold text-brand-blue text-lg md:text-xl mt-2 mb-0.5`}
  }

  h4 {
    ${tw`uppercase font-bold text-brand-cyan text-base`}
  }

  /* very small heading used for references title */
  h5 {
    ${tw`font-bold! text-2xs! pb-2`}
  }

  p {
    ${tw`text-base my-2`}
  }

  sub,
  sup {
    font-size: 60%;
  }

  a {
    ${tw`text-brand-cyan hover:text-brand-pink underline`}
  }

  ul {
    ${tw`mb-5`}
  }

  .footnote {
    ${tw`text-2xs text-brand-gray`}
  }

  .break-word {
    ${tw`break-words`}
  }
  /* To make placeholder value in forms select option light gray */
  select:invalid,
  select option[value=''] {
    color: #999999;
  }

  /* Use this setting for layout-intensive sites when font sizes are overridden by iOS */
  /* -webkit-text-size-adjust: none; */

  /* Focus visible & polyfill */
  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  /* Tab focus style */
  button:focus,
  input:focus,
  a:focus {
    outline: gray dashed 1px;
    outline-offset: 1px;
  }

  /* Left-align logo on OneTrust Cookie popup */
  #onetrust-pc-sdk .pc-logo {
    justify-content: start !important;
    height: 70px !important;
  }
`;

export const GlobalStyles = () => (
  <>
    <BaseStyles />
    <Global styles={customStyles} />
  </>
);
