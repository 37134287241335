/**
 * "You generally should implement the same components in both
 *  gatsby-ssr.js and gatsby-browser.js so that pages generated
 *  through SSR are the same after being hydrated in the browser."
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { AnimatePresence } from 'framer-motion';
import type { GatsbyBrowser } from 'gatsby';
import { isSSG } from './src/config/consts';
import { AppContextProviders } from './src/context';
import { GlobalStyles } from './src/styles/global-styles';
import './src/utils/polyfills';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => (
  <AppContextProviders location={props.location}>
    <GlobalStyles />
    <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
  </AppContextProviders>
);

export const onPreRouteUpdate: GatsbyBrowser['onPreRouteUpdate'] = ({
  location,
}) => {
  if (!isSSG && /\bd\b/.test(location.search)) {
    window['__d'] = 1;
  }
};
