import { TableData } from '.';

// Don't import <NERLYNX /> or other helpers here, breaks the server (import order issue or something)

export const ebcSafetyData: TableData = {
  groupHeading1: (
    <span key="ebc-group-head-1">
      {/* see note above */}
      <span tw="uppercase text-base font-bold md:(text-lg)">Nerlynx</span>{' '}
      (n=1408)
    </span>
  ),

  groupHeading2: <span key="ebc-group-head-2">Placebo (n=1408)</span>,

  headings: [
    <>All&nbsp;grades, %</>,
    <>Grades&nbsp;&ge;3, %</>,
    <>All&nbsp;grades, %</>,
    <>Grades&nbsp;&ge;3, %</>,
  ],

  rows: [
    [
      '{D}',
      '95',
      <>
        40 <small>(Grade 3)</small>
        <br />
        0.1 <small>(Grade 4)</small>
      </>,
      '35',
      '2',
    ],
    ['Nausea', '43', '2', '22', '0.1'],
    ['Abdominal pain*', '36', '2', '15', '0.4'],
    ['Fatigue', '27', '2', '20', '0.4'],
    ['Vomiting', '26', '3', '8', '0.4'],
    [
      <>
        Rash<sup>†</sup>
      </>,
      '18',
      '0.6',
      '9',
      '0',
    ],
    [
      <>
        Stomatitis<sup>‡</sup>
      </>,
      '14',
      '0.6',
      '6',
      '0.1',
    ],
    ['Decreased appetite', '12', '0.2', '3', '0'],
    ['Muscle spasms', '11', '0.1', '3', '0.1'],
    ['Dyspepsia', '10', '0.4', '4', '0'],
    [
      <>ALT increased</>,
      '9',
      <>
        1 <small>(Grade 3)</small>
        <br />
        0.2 <small>(Grade 4)</small>
      </>,
      '3',
      '0.2',
    ],
    [
      <>
        Nail disorder<sup>§</sup>
      </>,
      '8',
      '0.3',
      '2',
      '0',
    ],
    [
      <>AST increased</>,
      '7',
      <>
        0.5 <small>(Grade 3)</small>
        <br />
        0.2 <small>(Grade 4)</small>
      </>,
      '3',
      '0.3',
    ],
    ['Dry skin', '6', '0', '2', '0'],
    ['Abdominal distention', '5', '0.3', '3', '0'],
    ['Epistaxis', '5', '0', '1', '0.1'],
    ['Weight decreased', '5', '0.1', '0.5', '0'],
    ['Urinary tract infection', '5', '0.1', '2', '0'],
  ],
};
